<script lang="ts" setup>
import { useLogoStore } from "@/store/partner";

const logo = useLogoStore();

const hasPartnerLogoSmall = computed(() => !!logo.partnerLogo?.small);
</script>

<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 min-h-[100vh]">
    <div class="p-8 h-full">
      <main class="h-full">
        <suspense>
          <slot />
        </suspense>
      </main>
    </div>
    <div
      class="bg-dark-slate-gray flex items-center justify-center h-[100vh] min-h-full max-lg:hidden"
    >
      <div
        :class="{
          'divide-x': hasPartnerLogoSmall,
          'divide-gray-600': hasPartnerLogoSmall,
          flex: hasPartnerLogoSmall,
        }"
      >
        <img
          v-if="hasPartnerLogoSmall"
          class="px-3 max-h-[140px]"
          :src="logo.partnerLogo?.small"
        />
        <img class="px-3" src="/long-hurst-logo.svg" />
      </div>
    </div>
  </div>
</template>
